<template>
  <v-container
    fluid
    style=" background:rgba(255,255,255,0.9);min-width:100%; min-height:95vh; padding:10px 20px;position:relative;"
  >
    <h6
      @click="back"
      style="border:1px solid rgba(0,0,0,0.3); border-radius:5px; position:absolute; width:70px; cursor:pointer;"
    >
      <v-icon style="position:relative; top:-1px;">mdi-chevron-left</v-icon>back
    </h6>
    <div v-if="detail !== null" style="margin-bottom:65px;">
      <h4 class="oee-title">
        OEE DETAIL
        <v-tooltip bottom color="grey">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-small
              text
              icon
              color="grey darken-2"
              @click="loadPage"
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>refresh page</span>
        </v-tooltip>
      </h4>
      <br />
      <v-row no-gutters>
        <v-col cols="12" md="4" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Date
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;margin-top:6px;">
              <v-text-field dense readonly :value="convertDate(detail.date)" />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Start
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;margin-top:6px;">
              <v-text-field dense readonly v-model="detail.start_time" />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                End
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-text-field dense readonly v-model="detail.end_time" />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Shift
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;margin-top:6px;">
              <v-text-field dense readonly v-model="detail.schedule.shift" />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Machine
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;margin-top:6px;">
              <v-text-field
                v-if="detail.machine != null"
                dense
                readonly
                v-model="detail.machine.name"
              />
              <v-text-field v-else dense readonly />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Good Qty
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;margin-top:6px;">
              <v-text-field dense readonly v-model="detail.good_qty" />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Bad Qty
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;margin-top:6px;">
              <v-text-field dense readonly v-model="detail.not_good_qty" />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Availability
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;margin-top:6px;">
              <v-text-field
                dense
                readonly
                :value="(detail.availability * 100).toFixed(2) + '%'"
              />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Performance
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;margin-top:6px;">
              <v-text-field
                dense
                readonly
                :value="(detail.performance * 100).toFixed(2) + '%'"
              />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Quality
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;margin-top:6px;">
              <v-text-field
                dense
                readonly
                :value="(detail.quality * 100).toFixed(2) + '%'"
              />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                OEE
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;margin-top:6px;">
              <v-text-field
                dense
                readonly
                :value="(detail.oee * 100).toFixed(2) + '%'"
              />
            </v-col>
          </div>
        </v-col>
      </v-row>
      <br />
      <div style="margin-top:10px;">
        <v-toolbar-title class="overline">
          <p
            style="font-size: 16px; margin: 0; font-weight: bold; color:indigo; margin-bottom:0;"
          >
            List Downtime
          </p>
        </v-toolbar-title>
        <v-data-table
          mobile-breakpoint="0"
          v-model="selectedData"
          :items="downtimeList"
          :headers="headers"
          :items-per-page="10"
          :loading="loading"
          :dense="wWidth > 808 ? true : false"
          show-select
          item-key="id"
          single-select
          style="cursor: pointer;border:1px solid rgba(0,0,0,0.2);"
        >
          <template v-slot:top>
            <div
              style="border:thin solid rgba(0, 0, 0, 0.12);cursor:auto;padding:0 15px; "
            >
              <div style="width: 120px">
                <v-select
                  :disabled="selectedData.length === 0"
                  label="Action"
                  style="
                      position: relative;
                      top: 15px;
                      margin: 0 15px 0 5px;
                      font-size: 12px;
                    "
                  v-model="actionValue"
                  :items="itemsAction"
                  item-text="name"
                  item-value="id"
                  outlined
                  return-id
                  dense
                  @change="action"
                ></v-select>
              </div>
            </div>
          </template>
          <template v-slot:[`item.start_time`]="{ item }">
            <div
              style="font-size:12px; padding: 0; display: flex; flex-direction: row"
            >
              {{ convertTime(item.start_time) }}
            </div>
          </template>
          <template v-slot:[`item.end_time`]="{ item }">
            <div
              style="font-size:12px; padding: 0; display: flex; flex-direction: row"
            >
              {{ convertTime(item.end_time) }}
            </div>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <div
              style="width:70px; font-size:12px; padding: 0; display: flex; flex-direction: row"
            >
              {{ convertDate(item.date) }}
            </div>
          </template>
          <template v-slot:[`item.downtime_category_name`]="{ item }">
            <div
              style="width:120px; font-size:12px; padding: 0; display: flex; flex-direction: row"
            >
              {{ item.downtime_category_name }}
            </div>
          </template>
          <template v-slot:[`item.downtime_reason_name`]="{ item }">
            <div
              style="width:250px; font-size:12px; padding: 0; display: flex; flex-direction: row"
            >
              {{ item.downtime_reason_name }}
            </div>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <div
              style="width:250px; font-size:12px; padding: 0; display: flex; flex-direction: row"
            >
              {{ item.description }}
            </div>
          </template>
          <template v-slot:[`item.is_break`]="{ item }">
            <div
              style="font-size:12px; padding: 0; display: flex; flex-direction: row"
            >
              {{ item.is_break ? 'Yes' : 'No' }}
            </div>
          </template>
        </v-data-table>
      </div>

      <v-dialog
        v-if="selectedData.length === 1"
        v-model="changeStatusDialog"
        width="500"
        persistent
      >
        <v-form
          :disabled="loading"
          ref="entryForm"
          @submit.prevent="submitChange"
          style="position: relative;"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Change Status Downtime
            </v-card-title>

            <v-card-text class="d-flex justify-center align-center">
              <v-col
                cols="12"
                style="padding: 0 15px; height:45px; justify-content:flex-start; align-items:center; display:flex;"
              >
                <p
                  style="
                      margin:0;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Is Break ?
                </p>
                <v-checkbox
                  v-model="selectedData[0].is_break"
                  style=" margin:0 0 0 10px; height:25px; padding:0;"
                ></v-checkbox>
              </v-col>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                color="error"
                text
                outlined
                @click="closeDialog"
                :loading="loading"
              >
                Close
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                type="submit"
                text
                outlined
                :loading="loading"
              >
                Submit Now
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      wWidth: window.innerWidth,
      oee: buildType.apiURL('oee'),
      pagePath: this.$route.params.path,
      changeStatusDialog: false,
      detail: null,
      downtimeList: [],
      headers: [
        {
          text: 'Date',
          value: 'date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Category',
          value: 'downtime_category_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Reason',
          value: 'downtime_reason_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Start',
          value: 'start_time',
          align: 'left',
          sortable: false
        },
        {
          text: 'End',
          value: 'end_time',
          align: 'left',
          sortable: false
        },
        {
          text: 'Duration',
          value: 'duration',
          align: 'left',
          sortable: false
        },
        {
          text: 'Note',
          value: 'description',
          align: 'left',
          sortable: false
        },
        {
          text: 'Duration',
          value: 'duration',
          align: 'left',
          sortable: false
        },
        {
          text: 'Break ?',
          value: 'is_break',
          align: 'left',
          sortable: false
        }
      ],
      itemsAction: [
        { id: 0, name: '' },
        { id: 1, name: 'Change status is break' }
      ],
      status: null,
      actionValue: 0,
      selectedData: [],
      loading: false,

      snackbar: false,
      alertText: '',
      timeout: 2000,
      instance: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getOeeId', 'getUserProfile'])
  },
  watch: {
    selectedData() {
      console.log(this.selectedData)
    }
  },
  mounted() {
    this.loadPage()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel, pInputType, pInputAtt) {
      const title =
        pInputType === 'file'
          ? 'Upload bukti approvement pada kolom dibawah'
          : ''
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No',
            input: pInputType !== undefined ? pInputType : null,
            inputLabel: pInputType !== undefined ? title : null,
            inputAttributes: pInputAtt !== undefined ? pInputAtt : null
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    showMsgDialog2(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            confirmButtonText: 'Submit',
            cancelButtonText: 'No',
            input: 'textarea',
            inputLabel: 'Tolong isi alasan pada kolom dibawah sebelum submit',
            inputPlaceholder: 'Tulis sesuatu disini...',
            inputAttributes: {
              'aria-label': 'Tulis sesuatu disini'
            }
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    async loadPage() {
      if (this.$route.params.id !== undefined) {
        this.$store.commit('setOeeId', this.$route.params.id)
      }
      setTimeout(async () => {
        if (this.getOeeId !== null) {
          this.actionValue = 0
          this.selectedData = []
          await axios
            .get(`${this.oee}info?id=${this.getOeeId}`)
            .then(res => {
              console.log(res)
              this.loadDowntime(res.data.data)
              this.detail = res.data.data
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.$router.push('/oee/detail/' + this.$route.params.path)
        }
      }, 300)
    },
    async loadDowntime(detail) {
      await axios
        .get(
          `${this.oee}downtime/list?keyword=&offset=0&filter=[]&start_time=&end_time=&limit=all&oee_id=${this.getOeeId}`
        )
        .then(res => {
          console.log(res)
          this.downtimeList = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    back() {
      this.detail = null
      this.$store.commit('setOeeId', null)
      if (this.prevRoute !== undefined) {
        //     if (this.prevRoute.path !== '/oee') {
        this.$router.push(this.prevRoute.path)
        //     } else {
        //       this.$router.push('/oee/detail/' + this.$route.params.path)
        //     }
      } else {
        this.$router.push('/oee')
      }
    },
    action(event) {
      switch (event) {
        case 1:
          this.changeStatusIsBreak()
          break
      }
    },
    changeStatusIsBreak() {
      setTimeout(() => {
        this.changeStatusDialog = true
      }, 200)
    },
    submitChange() {
      axios
        .post(`${this.oee}downtime/change_status`, {
          id: this.selectedData[0].id,
          is_break: this.selectedData[0].is_break
        })
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }

          this.loading = false
          this.closeDialog()
        })
        .catch(err => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.closeDialog()
        })
    },
    closeDialog() {
      this.loadPage()
      setTimeout(() => {
        this.changeStatusDialog = false
      }, 200)
    },
    convertTime(raw) {
      if (raw !== null) {
        var time = raw.slice(0, raw.indexOf('+'))
        return time
      } else {
        return ''
      }
    },
    convertDate(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        var month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        // switch (month) {
        //   case '01':
        //     month = 'Jan'
        //     break
        //   case '02':
        //     month = 'Feb'
        //     break
        //   case '03':
        //     month = 'Mar'
        //     break
        //   case '04':
        //     month = 'Apr'
        //     break
        //   case '05':
        //     month = 'May'
        //     break
        //   case '06':
        //     month = 'Jun'
        //     break
        //   case '07':
        //     month = 'Jul'
        //     break
        //   case '08':
        //     month = 'Aug'
        //     break
        //   case '09':
        //     month = 'Sep'
        //     break
        //   case '10':
        //     month = 'Oct'
        //     break
        //   case '11':
        //     month = 'Nov'
        //     break
        //   case '12':
        //     month = 'Des'
        //     break
        // }
        return day + '-' + month + '-' + year
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.oee-title {
  margin: auto;
  width: 50%;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}
.items-total-div-1 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  min-height: 150px;
  width: 100%;
  justify-content: space-between;
  .items-total-div-2 {
    // padding: 10px 0;
    width: '40%';
    .items-total-box {
      border: 1px solid #e0e0e0;
      padding: 20px 10px;
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      font-size: 14px;
      div {
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        padding-top: 20px;
        display: flex;
        p {
          margin: 0;
          margin-right: 10px;
          min-width: 80px;
          position: relative;
          span {
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
  .items-total-div-3 {
    padding: 0 10px;
    width: 60%;
    font-size: 14px;
  }
}
.actionButton {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.fpb-status-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
@media (max-width: 808px) {
  .actionButton {
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 2;
    padding: 10px 0;
  }
  .items-total-div-1 {
    flex-direction: column-reverse;
    .items-total-div-2 {
      width: '100%';
      .items-total-box {
        width: 100%;
      }
    }

    .items-total-div-3 {
      margin-top: 10px;
      width: 100%;
    }
  }
}

@media (max-width: 840px) {
  .fpb-status-section {
    // flex-direction: column;
    flex-wrap: wrap;
  }
}
@media (max-width: 780px) {
  .oee-title {
    font-size: 3vw;
  }
}
</style>
